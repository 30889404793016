/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


//why doesn't environment.js handle this code?
global.$ = require("jquery")

require('jquery-ui');
require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/disable-selection');
require("jquery-ui/ui/widgets/datepicker");

require('popper.js');
import 'bootstrap';

global.moment = require('moment');
require('fullcalendar')
require('scheduler')
require('tempusdominus-bootstrap-4');
require('qtip2')


//my js files
require('application')


require('customers')
require('jobs')
require('payments')
require('quickbooks')
require('reports')
require('roles')
require('scheduler')
require('signature')
require('time_logs')
require('touch-punch')

import {utils} from "utilities";

var se = {};
(function(){
    this.utils = utils;
}).apply(se);
global.se = se;

require('credit_memos')
