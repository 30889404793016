$(document).ready(function(){
    
    if(document.getElementById("barChart1") !== null){
        loadBarGraphData();
    }
    
});



function loadBarGraphData(){
    
    var months = [];
    var values = [];
    var graph_type = null;
    loadDailyJobRevenue();
    $.getJSON({url: "/reports/graph_data.json", success: function(data){
        data["reports"].forEach(function(element){
           console.log(element["graph_type"]); 
           if(element["graph_type"] == "Customer Growth"){
               loadCustomerGrowth(element);
               
           }else if(element["graph_type"] == "Job Revenue"){
              
           }else if(element["graph_type"] == "Incomplete Jobs Status"){
               loadIncompleteJobs(element["jobs"]);
           }
        });
        
    }});

}

function loadDailyJobRevenue(){
    var data = $("#completedJobRevenue").data("values");
    data = data["days"];
    console.log(data);
    var labels = [];
    var thisYear = [];
    var lastYear = [];
    data.forEach(function(element) {
        labels.push(element["day"]);
        thisYear.push(element["revenue"]);
        lastYear.push(element["last_year_revenue"]);
    });
    var ctx = document.getElementById("completedJobRevenue");
    var myLineChart = new Chart(ctx, {
        type: 'line',
        data: {"labels": labels, 
               "datasets": [
                   {"label":"Completed By Technician Job Revenue (based on job end date)","data": thisYear, "fill":false,"borderColor":"rgb(75, 192, 192)","lineTension":0.1},
                   {"label":"Last Year","data": lastYear, "fill":false,"borderColor":"rgb(255,127,80)","lineTension":0.1}
                   ]
        },
        options: {
            tooltips: {
                callbacks: {
                    label: function(tooltipItems, data) {
                        return "Total Revenue: $" + parseInt(tooltipItems.yLabel.toString()).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                }
            },
            scales: {
            yAxes: [{
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function(value, index, values) {
                        return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                }
            }]
        }
        }
    });
        
}

function loadIncompleteJobs(data){
    console.log(JSON.stringify(data));
    var labels = [];
    var datasets = [];
    data.forEach(function(element) {
        labels.push(element["status"]);
        datasets.push(element["count"]);
    });
    var ctx = document.getElementById("incompleteJobsStatuses");
    var myDoughnutChart = new Chart(ctx, {
        type: 'doughnut',
        data: {"labels": labels, "datasets": [{"label":"Completed Job Revenue","data": datasets, "backgroundColor":["#0275d8","#5cb85c","#5bc0de","#f0ad4e","#d9534f"]}]},
        options: {}
    });
}


function loadCustomerGrowth(data){
    var months = [];
    var values = [];
    var graph_type = null;
    graph_type = data["graph_type"];
        
    data["months"].forEach(function(element) {
        months.push(element["month"]);
        values.push(element["count"]);
    });
       
    var ctx = document.getElementById("barChart1").getContext('2d');
    var myChart = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: months,
            datasets: [{
                label: graph_type,
                data: values,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)'
                ],
                borderColor: [
                    'rgba(255,99,132,1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ],
                borderWidth: 1
            }]
        },
        options: {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }]
            }
        }
    });
}