import {Signature} from 'signature';

$(function () {
        
        
        if (!se.utils.isMobileDevice()) {
            var element = document.getElementById("unscheduled-jobs");
            if (typeof(element) != 'undefined' && element != null){
                document.getElementById("unscheduled-jobs").className = "collapse show";
            }
        }else{
            $("#pills-tab-ca").addClass("flex-column"); //customer account
        }
        
        $('.draggable').each(function() {
            // make the event draggable using jQuery UI
            $(this).draggable({
                revert: function(){
                    return true;
                },
                start: function (event, ui) {
                    $(this).hide();
                },
                stop: function (event, ui) {
                    $(this).show();
                },
                helper: "clone",
                appendTo: "#external-events",
                revertDuration: 0,
                zIndex: 999
            });
        });
        
        $(document).on("click", "#total-cost", function(){
            var $costValue = $("#total-cost-value");
            if($costValue.text() == "******"){
                $costValue.text("$" + $costValue.data("cost"));
            }else{
                $costValue.text("******");
            }
        });
        
        $(".job-location").change(function(){
           alert("Changing your location will remove all the associated job inventory after you update.");
        });
        $("#item-search-field").keyup(se.utils.debounce(function(){
            if($(this).val() != ""){
                Rails.fire($("#item-search")[0], 'submit');
                
            }else{
                $("#items").html("");
            }
        }, 250));
        
       
        $('#job_start').datetimepicker({
            stepping: 15,
            useCurrent: false,
            ignoreReadonly: true
        });
        $('#job_end').datetimepicker({
            useCurrent: false,
            stepping: 15,
            ignoreReadonly: true
        });
        $("#job_start").on("change.datetimepicker", function (e) {
            var end = $('#job_end').datetimepicker('date');
            var start = $('#job_start').datetimepicker('date');
           
            $('#job_end').datetimepicker('minDate', e.date.add(1, "minutes"));
            if (end !== null){
                if (start.isSameOrAfter(end, "seconds")){
                    $('#job_end').datetimepicker('date', start.add(59, "minutes"));   
                }
            }
            
        });
        $("#job_end .input-group-text").on("click", function(){
            var start = $('#job_start').datetimepicker('date');
            var end = $('#job_end');

            if (end.datetimepicker('date') === null && start !== null){
                end.datetimepicker('date', start.add(59, "minutes"));    
            }
        });
        $("#job_end").on("change.datetimepicker", function (e) {
            var start = $('#job_start').datetimepicker('date');
        
            if (start !== null){
                $('#job_end').datetimepicker('minDate', start.add(1, "minutes"));
            }
            //$('#job_start').datetimepicker('maxDate', e.date.subtract(1, "minutes"));
        });
    });

$(document).ready(function(){
    $("#sameAsDescription").click(function(){
        console.log("test");
        if(this.checked == true){
             $("#document_memo").val($("#job_description").val());
        }else{
            $("#document_memo").val("");
        }
    }); 
   
    
    $(document).on("click", ".click-to-sign", function(){
        var t = $(this);
        var oldWidth = window.innerWidth;
        var screenWidthResized = () => new Promise(function(resolve, reject) {
            window.addEventListener('resize', function(){
                if(oldWidth < window.innerWidth){
                    console.log("OLD WIDTH: " + oldWidth);
                    console.log("NEW WIDTH: " + window.innerWidth);
                    resolve(true);
                }
            });
        });
        
        
        if(!se.utils.isIOSDevice() && !se.utils.isSamsungBrowser() && se.utils.isMobileDevice()){
            document.documentElement.requestFullscreen().then(function(result){
                return screen.orientation.lock('landscape');
            }).then(function(){
                return screenWidthResized();
            }).then(function(){
                setupScreen();
            }).catch(function(err){
                setupScreen();
            });
        }else{
            setupScreen();
        }
        
       
        function setupScreen(){
            var body = document.body.innerHTML;
            document.body.innerHTML = "";
            document.body.className = "disable-refresh";
            
            var signatureElements = Signature.html();
            var signaturePad = signatureElements[0];
            document.body.appendChild(signatureElements[1]);
            
            var sig = new Signature(signaturePad, body);
            sig.initListeners().then(function(imageData){
                 var blank = sig.isCanvasBlank();
                 $(".form-with-signature-submit-btn").each(function(){
                       $(this).attr("disabled", blank);	 
                 });
                 se.utils.scrollToBottomOfPage();
                 
                 sig.resize(imageData, 600, 300).then(function(resizedImage){
                    
                    document.getElementById(t.data("signatureId")).value = resizedImage;
                    var image = document.getElementById(t.data("signatureId") + "Image");
                    image.src = resizedImage;
                    image.className = "";
                     
                    var signBtn = document.getElementById(t.data("signatureId") + "Sign");
                    signBtn.className="click-to-sign fa fa-pencil edit-signature top-right"; 
                    signBtn.innerHTML = "";
                    document.body.className = "";
                    try{
                        document.exitFullscreen();
                    }catch(err){}
                    
                 });
            });
        }
       
    });
    
});//end doc



