var utils = (function(){
    return {
        isIOSDevice: function(){
            return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        },
        
        isMobileDevice: function(){
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        },
        
        scrollToBottomOfPage: function(){
            var e = (document.scrollingElement || document.body);
            e.scrollTop = e.scrollHeight;
        },
        
        isSamsungBrowser: function(){
            return !!navigator.userAgent.match(/SamsungBrowser/i)
        },
        
        debounce: function(func, wait, immediate){
            var timeout;
        	return function() {
        		var context = this, args = arguments;
        		var later = function() {
        			timeout = null;
        			if (!immediate) func.apply(context, args);
        		};
        		var callNow = immediate && !timeout;
        		clearTimeout(timeout);
        		timeout = setTimeout(later, wait);
        		if (callNow) func.apply(context, args);
        	};
        },
        initFormSubmit: function(){
            $(".form-with-required-readonly-fields").submit(function(e){
               $("input.btn").each(function(){
                    $(this).attr("disabled", true);
               });
               var prevent = false;
               $(this).find(".required").each(function(){
                   if($(this).val() == ""){
                       $(this).addClass("border-danger");
                       prevent = true
                       e.preventDefault();
                      
                   }else{
                       $(this).removeClass("border-danger");
                   }
               });
               if(prevent == true){
                  $("input.btn").each(function(){
                     $(this).attr("disabled", false);
                  });
                  return false;
               }
            });
        },
        initDatepicker: function(){
                $( ".datepicker" ).datepicker();
                $(".datepickerV2").datepicker({
                    dateFormat: "yy-mm-dd"
                });
        },
        
        initSortable: function(){
            var t = this;
            $( ".sortable" ).sortable({
                update: function(event, ui) {
                    t.setRowNames();
                },
                handle: ".move"
            });
            $( ".sortable" ).disableSelection();    
        },                                                  
        
        formatQuantity: function(){
                var quantityValue;
                $('.qty').each(function(){
                    quantityValue = parseFloat($(this).val());
                    $(this).val(quantityValue.toString());
                });
         },
            
         formatCurrency: function(){
                $('.currency').each(function(){
                    $(this).val(currency($(this).val()));
                });
         },
         setRowNames: function(){
            var counter = 0;
            var $this, $lineNum;
            var $type = $('#body').data("type");
            $('#body tr').each(function(){
                $this = $(this);
                $this.find('.id').attr('name', $type + "[" + $type + "_line_items_attributes[" + counter + "][id]");
                $this.find('.item_id').attr('name', $type + "[" + $type + "_line_items_attributes[" + counter + "][item_id]");
                
                $lineNum = $this.find('.line_num');
                $lineNum.attr('name', $type + "[" + $type + "_line_items_attributes[" + counter + "][line_num]");
                $lineNum.val(counter + 1);
                
                $this.find('.description').attr('name', $type + "[" + $type + "_line_items_attributes][" + counter + "][description]");
                
                $this.find('.service_date').attr('name', $type + "[" + $type + "_line_items_attributes][" + counter + "][service_date]");
                $this.find('.quantity').attr('name', $type + "[" + $type + "_line_items_attributes[" + counter + "][quantity]");
                $this.find('.price').attr('name', $type + "[" + $type + "_line_items_attributes[" + counter + "][price]");
                $this.find('.total').attr('name', $type + "[" + $type + "_line_items_attributes[" + counter + "][total]");
                $this.find('.link-quantities-hidden').attr('name', $type + "[" + $type + "_line_items_attributes[" + counter + "][link_quantities]");
                $this.find('.link-quantities').attr('name', $type + "[" + $type + "_line_items_attributes[" + counter + "][link_quantities]");
                $this.find('.cost-quantity').attr('name', $type + "[" + $type + "_line_items_attributes[" + counter + "][cost_quantity]");
                $this.find('.cost').attr('name', $type + "[" + $type + "_line_items_attributes[" + counter + "][cost]");
                $this.find('.total-cost').attr('name', $type + "[" + $type + "_line_items_attributes[" + counter + "][total_cost]");
                $this.find('.destroy').attr('name', $type + "[" + $type + "_line_items_attributes[" + counter + "][_destroy]");
                counter++;
            });
        },//start
        calculateTotals: function(){
                var $type = $('#body').data("type");
                var itemsAmount = currency(0);
                var costAmount = currency(0);
                var $this;
                $('#body tr').each(function(){
                    $this = $(this);
                    if(!$this.find(".destroy").is(":checked")){
                        itemsAmount = itemsAmount.add($this.find('.total').val());
                        costAmount = costAmount.add($this.find('.total-cost').val());
                    }
                });
                
                var subtotal = itemsAmount;
                var taxPercent;
                if($type == "document"){
                    taxPercent = $('#tax-id').find(":selected").data("percent");   
                }else{
                    var taxes = $("#body").data("taxes");
                    var zipCode = $("#job_zip").val();
                    for(var i = 0; i < taxes.length; i++){
                        if(zipCode >= taxes[i]["start_zip_code"] && zipCode <= taxes[i]["end_zip_code"]){
                               taxPercent = taxes[i]["percentage"];
                        }
                    }
                }
                var tax = currency(0);
                if(taxPercent != null){
                    tax = itemsAmount.multiply(taxPercent);
                }
               
                if($type == "document"){
                    $("#subtotal").val(itemsAmount);
                    $("#total-tax").val(tax);
                    $("#grand-total").val(currency(itemsAmount).add(tax));
                }else{
                    $("#subtotal").text(itemsAmount);
                    $("#total-tax").text(tax);
                    $("#grand-total").text(currency(itemsAmount).add(tax));
                }
                //used to update the total cost value
                var $tcv = $("#total-cost-value");
                $tcv.data("cost", costAmount);
                if($tcv.text() != "******"){
                    $tcv.text("$" + costAmount);
                }
        }
    };
})();

export {utils}