$(document).ready(function(){
   

    $(document).on("keyup", ".itemQuery", se.utils.debounce(function(){
       
       var td = $(this).closest(("td"));
       var results = td.find(".results");
       results.html("");
       if($(this).val() == ""){
            return
       }
       $.get("/items/search", { q: $(this).val() }, function( data ) {
          
           if(data.length > 0){
               for(var i = 0; i < data.length; i++){ 
                   results.append('<li class="bg-light badge d-block items" data-value="' + data[i]["id"] + '" data-name="' + (data[i]["brand"] +" "+ data[i]["name"] +" "+ data[i]["part_number"]).trim() + '" data-description="' + data[i]["description"].trim() + '" data-price="' + currency(data[i]["price"]) + '" data-cost="' + currency(data[i]["cost"]) +'"><button type="button" class="btn btn-sm btn-success fa fa-plus text-light add-item-btn"></button> ' + (data[i]["brand"] +" "+ data[i]["name"] +" "+ data[i]["part_number"] + " - $" + currency(data[i]["price"])).trim() +'</li>');
               }
           }
           
         }); 
    }, 250));
    
    $(document).on("click", ".add-item-btn", function(){
        let li = $(this).closest("li");
        let tr = $(this).closest("tr");
        let name = li.data("name");
        let value = li.data("value");
        let price = li.data("price");
        let cost = li.data("cost");
        let description = li.data("description") || li.data("name");
        let item_id = tr.find(".item_id");
        
        tr.find(".price").val(price).trigger("input");
        tr.find(".cost").val(cost).trigger("input");
        let itemQuery = tr.find(".itemQuery");
        itemQuery.val(name);
        itemQuery.removeClass("error-input-field");
        if (item_id.val() != value){
            tr.find(".description").val(description);
        }
        
        item_id.val(value);
        
        tr.find(".results").html("");

    });
    
   
    if($("#tax-id").val() == ""){
         $("#total-tax").attr("readonly", true);
    }
        
    $(document).on("click", "#add-row", function(){
       $(".costCollapse").collapse("hide");
       var $type = $('#body').data("type");
       var date = "";
       if($type !== "job"){
           date = "<td data-title='Date'><input class='datepickerV2 form-control required service_date' placeholder='Date' required='required' readonly='readonly' type='text'></td>";
       }
       var row = "<tr>" +
                      "<input class='id' type='hidden'>" +
                      "<input class='line_num' type='hidden'>" +
                   	  "<input class='item_id required' type='hidden'>" +
                   	  "<td><span class='move fa fa-sort'></span></td>" +
                   	  date +
                      "<td data-title='Item'>" +
                         	"<input type='text' class='form-control itemQuery required' placeholder='Search Items'>" +
                         	"<ul class='results'></ul>" +
                      "</td>" +
                      "<td data-title='Desc.'><input class='form-control description' type='text'></td>" +
                      "<td data-title='Qty'><input class='form-control quantity qty' required='required' step='any' min='0' type='number' value='1'>" + costAccordion("<label class='font-weight-bold mt-2 mb-0'>Qty</label><i class='fa fa-link'></i><input class='link-quantities-hidden' type='hidden' value='0'><input class='link-quantities' type='checkbox' value='1' checked='checked'><input class='form-control cost-quantity qty' required='required' step='any' min='0' readonly='readonly' type='number' value='1'>") + "</td>" +
                      "<td data-title='Price'><input class='form-control price currency' required='required' step='.01' min='0' type='number'>" + costAccordion("<label class='font-weight-bold mt-2 mb-0'>Cost</label><input class='form-control cost currency' required='required' step='.01' min='0' type='number'>") + "</td>" +
                      "<td data-title='Total'><input class='form-control total currency' required='required' step='.01' min='0' type='number'>" + costAccordion("<label class='font-weight-bold mt-2 mb-0'>Total cost</label><input class='form-control total-cost currency' required='required' step='.01' min='0' type='number'>") + "</td>" +
                      "<td data-title='Delete' class='delete'></td>" +
                  "</tr>"
                            
        $("#body").append(row);
        var tr = $( "#body tr:last" );
       
        tr.find(".delete").html("<button type='button' class='btn btn-danger btn-small remove-row fa fa-trash'></button>")
        se.utils.setRowNames();
        se.utils.initDatepicker();
        
    });
    
    
    $(document).on("click", ".remove-row", function(){
        var rowCount = $('#body tr').length;
        if(rowCount > parseInt($('#body').data("min"))){
            var $tr = $(this).closest("tr");
            $tr.remove();
            se.utils.setRowNames();
            se.utils.calculateTotals();
        }
    });
    $(".job_zip").on("keyup", function(){
       se.utils.calculateTotals(); 
    });
    $(document).on("keyup", "#total-tax", function(){
        $("#grand-total").val(
            currency($("#total-tax").val()).add($("#subtotal").val())
        );
    });
    
    $(document).on("change", "#tax-id", function(){
        
        if($(this).val() == ""){
            $("#total-tax").attr("readonly", true);
        }else{
            $("#total-tax").attr("readonly", false);
        }
       se.utils.calculateTotals(); 
    });
    $(document).on("click", ".destroy", function(){
        se.utils.calculateTotals();
    });
    
    $(document).on("keyup", ".total", function(){
        console.log("calc total");
        var element = $(this)
        var $tr = $(this).closest("tr");
        var total = element.val();
        var price = $tr.find(".price").val();
        var quantity = $tr.find(".quantity");
        var val = parseFloat((total/price).toFixed(10));
        
        quantity.val(val.toString());

        se.utils.calculateTotals();
        
    });
    
    $(document).on("keyup", ".total-cost", function(){
        console.log("calc total cost");
        var element = $(this)
        var $tr = $(this).closest("tr");
        var totalCost = element.val();
        var cost = $tr.find(".cost").val();
        var costQty = $tr.find(".cost-quantity");
        var val = parseFloat((totalCost/cost).toFixed(10));
        
        costQty.val(val.toString());

        se.utils.calculateTotals();
        
    });
    
   
    $(document).on("keyup mouseup input", ".price, .quantity", function(){
        console.log("price quantity fired");
        var tr = $(this).closest("tr");
        var quantity = tr.find(".quantity").val();
        var price = tr.find(".price").val();
        var amount = tr.find(".total");
        amount.val(currency(price).multiply(quantity));
        var linkQty = tr.find(".link-quantities");
        if(linkQty.is(":checked")){
            tr.find(".cost-quantity").val(quantity);
            calculateCostLineTotal(tr);
        }
       se.utils.calculateTotals();
    });
    
    $(document).on("keyup mouseup input", ".cost, .cost-quantity", function(){
        var tr = $(this).closest("tr");
        calculateCostLineTotal(tr);
        se.utils.calculateTotals();
    });
    
    $(document).on("click", ".link-quantities", function(){
        var tr = $(this).closest("tr");
        var costQty = tr.find(".cost-quantity");
        if($(this).is(":checked")){
            var qty = tr.find(".quantity").val();
            costQty.attr("readonly", true);
            costQty.val(qty);
            calculateCostLineTotal(tr);
            se.utils.calculateTotals();
        }else{
           costQty.attr("readonly", false); 
        }
    });
    
    function calculateCostLineTotal(tr){
        var costQty = tr.find(".cost-quantity").val();
        var cost = tr.find(".cost").val();
        var totalCost = tr.find(".total-cost");
        totalCost.val(currency(cost).multiply(costQty));
    }
    
    function costAccordion(html){
        if($("#total-cost").length){
            return "<div class='costCollapse collapse'>" + html + "</div>";
        }else{
            return "";
        }
    }
    
  
});


