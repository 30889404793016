export class Signature {
    
    constructor(canvas, body) {
        this.signed = false;
        this.prevX = 0;
        this.currX = 0;
        this.prevY = 0;
        this.currY = 0;
        this.dot_flag = false;
        this.canvas = canvas;
        this.ctx = this.canvas.getContext("2d");
        this.w = this.canvas.width;
        this.h = this.canvas.height;
        this.prevBody = body;
    }
    
    static html(){
        var signatureContainer = document.createElement("div");
        signatureContainer.className = "signature-container";
        
        var signatureToolbar = document.createElement("div");
        signatureToolbar.className = "signature-toolbar";
      
        var erase = document.createElement("button");
        erase.className = "fa fa-trash widget float-left erase-btn";
        signatureToolbar.appendChild(erase);
        
        var title = document.createElement("h1");
        title.className = "d-inline-block";
        title.appendChild(document.createTextNode("Sign Below"));
        signatureToolbar.appendChild(title);
        
        var closeSignature = document.createElement("button");
        closeSignature.className = "fa fa-times-circle widget float-right close-signature-btn";
        signatureToolbar.appendChild(closeSignature);
        
        var signaturePad = document.createElement("canvas");
        signaturePad.id = "canvas";
        if(window.innerWidth >= 600){
          signaturePad.height = 300;
          signaturePad.width = 600; 
          signatureToolbar.style = "width:600px;";
        }else{
          signaturePad.height = window.innerWidth/2;
          signaturePad.width = window.innerWidth; 
          signatureToolbar.style="width:" +window.innerWidth + ";";
        }
        signaturePad.className = "signature-pad";
       
        signatureContainer.appendChild(signatureToolbar);
        signatureContainer.appendChild(signaturePad);
        
        return [signaturePad, signatureContainer];
    }
  
  initImage(){
        var context = this.canvas.getContext('2d');
        var img = new Image();
        img.src = this.signature;
        img.onload = function(){
            context.drawImage(img,0,0);
        }
  }
  
  initListeners(){
        var ref = this;
        document.body.addEventListener("touchmove", function (e) {
            console.log("touchmove");
            if (e.target.nodeName == 'CANVAS') { 
                e.preventDefault(); 
                ref.mobilexy('move', e);
            }
        }, { passive: false });
        document.body.addEventListener("touchstart", function (e) {
            console.log("touchstart");
            if (e.target.nodeName == 'CANVAS') { 
                e.preventDefault(); 
                ref.mobilexy('down', e);    
            }
        }, { passive: false });
        document.body.addEventListener("touchleave", function (e) {
            console.log("touchleave");
            if (e.target.nodeName == 'CANVAS') { 
                e.preventDefault(); 
                ref.mobilexy('up', e);   
            }
        }, { passive: false });
        
        
        this.canvas.addEventListener("mousemove", function (e) {
            ref.findxy('move', e);
        }, false);
        this.canvas.addEventListener("mousedown", function (e) {
            ref.findxy('down', e);
        }, false);
        this.canvas.addEventListener("mouseup", function (e) {
            ref.findxy('up', e);
        }, false);
        this.canvas.addEventListener("mouseout", function (e) {
            ref.findxy('out', e);
        }, false); 
        
        document.getElementsByClassName("erase-btn")[0].addEventListener("click", function(){
            ref.erase();
        });
        
        return new Promise(function(resolve, reject){
            document.getElementsByClassName("close-signature-btn")[0].addEventListener("click", function(){
                resolve(ref.close());
            });  
        });
  }

  color(obj) {
        switch (obj.id) {
            case "green":
                x = "green";
                break;
            case "blue":
                x = "blue";
                break;
            case "red":
                x = "red";
                break;
            case "yellow":
                x = "yellow";
                break;
            case "orange":
                x = "orange";
                break;
            case "black":
                x = "black";
                break;
            case "white":
                x = "white";
                break;
        }
        if (x == "white") y = 14;
        else y = 2;

    }

    draw() {
        this.signed = true;
        this.ctx.beginPath();
        this.ctx.moveTo(this.prevX, this.prevY);
        this.ctx.lineTo(this.currX, this.currY);
        this.ctx.strokeStyle = this.x;
        this.ctx.lineWidth = 3; //was this.y;
        this.ctx.stroke();
        this.ctx.closePath();
    }
    
    close(){
        document.body.innerHTML = this.prevBody;
        return this.canvas.toDataURL();
    }
    
    isCanvasBlank(){
        return !this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height).data.some(channel => channel !== 0);
    }
    resize(image, width, height){
        
        return new Promise(function(resolve, reject){
             var canvas = document.createElement("canvas");
             var context = canvas.getContext('2d');
             var img = new Image();
            
             img.onload = function(){
                  canvas.width= width;
                  canvas.height = height;
                  img.width = width;
                  img.height = height;
                  context.drawImage(img, 0, 0, img.width, img.height);
                  resolve(canvas.toDataURL());
             }
             
             img.src = image;
        });
    }
   
    erase() {
        this.signed = false;
        this.ctx.clearRect(0, 0, this.w, this.h);
    }

    findxy(res, e) {
        
        if (res == 'down') {
            this.prevX = this.currX;
            this.prevY = this.currY;
            this.currX = e.pageX - this.canvas.offsetLeft;
            this.currY = e.pageY - this.canvas.offsetTop;
           
            this.flag = true;
            this.dot_flag = true;
            if (this.dot_flag) {
                this.ctx.beginPath();
                this.ctx.fillStyle = this.x;
                this.ctx.fillRect(this.currX, this.currY, 2, 2);
                this.ctx.closePath();
                this.dot_flag = false;
            }
        }
        if (res == 'up' || res == "out") {
            this.flag = false;
        }
        if (res == 'move') {
            if (this.flag) {
                this.prevX = this.currX;
                this.prevY = this.currY;
                this.currX = e.pageX - this.canvas.offsetLeft;
                this.currY = e.pageY - this.canvas.offsetTop;
                this.draw();
            }
        }
    }

    mobilexy(res, e) {
        e.preventDefault();
        var touch = event.touches[0];
        if (res == 'down') {
            this.prevX = this.currX;
            this.prevY = this.currY;
            this.currX = touch.pageX - this.canvas.offsetLeft;
            this.currY = touch.pageY - this.canvas.offsetTop;

            this.flag = true;
            this.dot_flag = true;
            if (this.dot_flag) {
                this.ctx.beginPath();
                this.ctx.fillStyle = this.x;
                this.ctx.fillRect(this.currX, this.currY, 2, 2);
                this.ctx.closePath();
                this.dot_flag = false;
            }
        }
        if (res == 'up' || res == "out") {
            this.flag = false;
        }
        if (res == 'move') {
            if (this.flag) {
                this.prevX = this.currX;
                this.prevY = this.currY;
                this.currX = touch.pageX - this.canvas.offsetLeft;
                this.currY = touch.pageY - this.canvas.offsetTop;
                this.draw();
            }
        }
    }

}

