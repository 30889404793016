$(document).ready(function(){
    var depositAccountSet = false;
     $(".payment-method-tabs .credit-card").click(function(){
        setDepositAccount($(this).data("defaultDepositAccount"));
    });
    $(".payment-method-tabs .opt").click(function(){
        setDepositAccount(null);
    });
    $(".payment-type").change(function(){
       setDepositAccount(null)
    });
    $("#payment_quick_books_account_id").on("change click", function(){
       depositAccountSet = true;
    });
    function setDepositAccount(val){
        if(depositAccountSet == false){
           if( val === undefined || val === null || val == ''){
               val = $("#payment_payment_type_id").children("option:selected").data("depositAccount");
           }
           if( val !== undefined && val !== null && val != ''){
                $("#payment_quick_books_account_id").val(val);
           }else{
               $("#payment_quick_books_account_id").val("");
           }
        }
    }
    
    
   $(".payable").click(function(){
        var tr = $(this).closest("tr");
        var amount = tr.find(".pay-amount");
        if($(this).is(":checked")){
            amount.val(amount.attr("max"));
            amount.attr("disabled", false);
        }else{
            amount.attr("disabled", true);
        }
        setTotals();
   }); 
   
   $(".pay-amount").change(function(){
      if(currency($(this).val()).intValue == 0){
         $(this).attr("disabled", true);
         var tr = $(this).closest("tr");
         tr.find(".payable").prop('checked', false);
      }
      setTotals();
   });
   
   $("#payment_amount").change(function(){
       var tr;
       $('#credit-memos .payable').each(function(){
            $(this).prop('checked', false);
            tr = $(this).closest("tr");
            tr.find(".pay-amount").prop('disabled', true);
        });
        
        var amount = currency($(this).val());
        var max;
        $('#invoices .pay-amount').each(function(){
            max = currency($(this).attr('max'));
            tr = $(this).closest("tr");
            if(amount.intValue >= max.intValue){
                $(this).val(max);
                tr.find(".payable").prop('checked', true);
                $(this).prop('disabled', false);
                amount = amount.subtract(max);
            }else{
                $(this).val(amount);
                if(amount.intValue > 0){
                     tr.find(".payable").prop('checked', true);
                     $(this).prop('disabled', false); 
                }else{
                     tr.find(".payable").prop('checked', false);
                     $(this).prop('disabled', true); 
                }
                amount = currency(0);
            }
        
            
        });
        if(currency($(this).val()).intValue > 0){
                $("#payWithStripe").attr("disabled", false);
                
            }else{
                $("#payWithStripe").attr("disabled", true);
               
            }
       
       
   });
   
   
   
}); 

function setTotals(){
    var total_credit_memos = currency("0")
    $('#credit-memos .pay-amount:enabled').each(function(){
        total_credit_memos = total_credit_memos.add(currency($(this).val()));
    });
    
    var total_invoices = currency("0")
    $('#invoices .pay-amount:enabled').each(function(){
        total_invoices = total_invoices.add(currency($(this).val()));
    });
    
    console.log("invoices" + total_invoices);
    console.log("cmemos" + total_credit_memos);
    var max;
    var total = total_invoices;
   // if(total.intValue - total_credit_memos.intValue <= 0){
        $('#credit-memos .pay-amount:enabled').each(function(){
            
            max = currency($(this).attr('max'));
            if (total.intValue > max.intValue){
                $(this).val(max);
                total = total.subtract(max);
            }else{
                $(this).val(total);
                total = currency(0);
            }
           
        });
        
   // }
   
    
    $("#payment_amount").val(currency(total));
    if(currency(total).intValue > 0){
        $("#payWithStripe").attr("disabled", false);
    }else{
        $("#payWithStripe").attr("disabled", true);
    }
    
    
}