
$(document).ready(function(){
    $(".tc-paginate").click(function(){
        $("#week").val($(this).val());
        $("#tl-search").submit();
    });
    
    $("#tl-search select").change(function(){
        $(this).closest("form").submit();
    })
});