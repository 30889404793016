
$(document).ready(function(){
   $("#customer-search-field").keyup(se.utils.debounce(function(){
        Rails.fire($("#customer-search")[0], 'submit');
   }, 250));
        
   $(document).on('click', '#customer-submit-btn', function(){
        
        if(document.getElementById("sameAsBilling").checked == true){
            $("#customer_addresses_attributes_1_line_1").val($("#customer_addresses_attributes_0_line_1").val());
            $("#customer_addresses_attributes_1_line_2").val($("#customer_addresses_attributes_0_line_2").val());
            $("#customer_addresses_attributes_1_city").val($("#customer_addresses_attributes_0_city").val());
            $("#customer_addresses_attributes_1_state").val($("#customer_addresses_attributes_0_state").val());
            $("#customer_addresses_attributes_1_zip_code").val($("#customer_addresses_attributes_0_zip_code").val());
            
        }
    });
    
     $(document).on('click', '#qb-customers-btn', function(){
         $("#qb-customers").prop("disabled", "true");
         var myList;
         $.get("/quickbooks/search_customers", { first_name: $("#customer_first_name").val(), last_name: $("#customer_last_name").val(), company: $("#customer_company").val(), email: $("#customer_email").val() }, function( data ) {
           $("#qb-customers").find('.remove').remove();
           if(data.length > 0){
               
               for(var i = 0; i < data.length; i++){
                   myList = [];
                    $('#qb-customers option').each(function() {
                        myList.push($(this).val());
                    });
                    
                    
                    if($.inArray(data[i]["id"], myList) == -1){
                        $("#qb-customers").append('<option class="remove" value="' + data[i]["id"] + '">' + data[i]["display_name"] + '</option>');
                    }else{
                        $("#qb-customers option[value='" + data[i]["id"] + "']").text(data[i]["display_name"]);
                    }
               }
           }
            $("#qb-customers").prop("disabled", false);
         });
         
    });
    
    $(document).on('change', '#qb-customers', function(){
        var text = $(this).children("option:selected").text();
        if(text.includes("No customer selected") || text.includes("Name Unavailable")){
            $("#customer_quickbooks_customer_id").val("");
            $("#customer_quick_books_display_name").val("");
        }else{
            $("#customer_quickbooks_customer_id").val($(this).val());
            $("#customer_quick_books_display_name").val(text);
        }
    });
    
    
    $(document).on("keyup", "#customer_company", function(){
       $("#customer_quick_books_display_name").val($(this).val());
       if(!$.trim($("#customer_company").val()).length) {
            $("#customer_quick_books_display_name").val($("#customer_first_name").val() + " " + $("#customer_last_name").val()); 
       }
    });
    
    $(document).on("keyup", "#customer_first_name, #customer_last_name", function(){
       if(!$.trim($("#customer_company").val()).length) {
            $("#customer_quick_books_display_name").val($("#customer_first_name").val() + " " + $("#customer_last_name").val()); 
       }
    });
   
});