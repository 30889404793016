

$(document).ready(function(){
    
    se.utils.initFormSubmit();
   
    $(document).on("mousedown", ".qty", function(){
       $(this).attr("step", ".5");
    });
    
    $(document).on("keyup mouseup", ".qty", function(){
       $(this).attr("step", ".01");
    });
    
    $(document).on("keyup",".phone", function(){
        var key = event.keyCode || event.charCode;
        if( key == 8 || key == 46 ){
            return false;
        }
        var number = $(this).val();
        if(number.length == 3 || number.length == 7){
            number += "-";
            $(this).val(number);
        }
        
    });
    
    $( ".sortable-ul" ).sortable({
        update: function(event, ui) {
            var count = 1;
            $('.sortable-ul li').each(function(){
                $this = $(this);
                $this.find(".line-num").val(count);
                count++;
            });
        },
        handle: ".move"
    });
    
    $( ".sortable" ).disableSelection(); 
    
  
    
    
    var quantityValue;
    $(document).on("change", ".qty", function(){
    
        quantityValue = parseFloat($(this).val());
        $(this).val(quantityValue.toString());
        
    });
    
    $('#save-filter-btn').on('click', function(){
        var form = $(this).closest("form");
        saveSearchFilter(form.attr('action') + '?' + form.serialize());
        location.reload();
    });
    
     $('#reset-filters-btn').on('click', function(){
        if(confirm("Are you sure?")){
           document.cookie="job_links=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
           location.reload();
        }
    });
   
    $('textarea.autosize').each(function() {
        $(this).height($(this).prop('scrollHeight'));
    });
    
    se.utils.initDatepicker();
    se.utils.initSortable();
    
    
    $(document).on("click", "body", function(evt){    
           if(evt.target.id == "mySidenav" || evt.target.id == "submenu-btn" || evt.target.id == "sm-arrow"){
              return;
           }
           if($(evt.target).closest('#mySidenav').length){
               return;
           }
        closeNav();
    });
    
    $(".submenu").on("click", function(){
        openNav();
    });
    $(".closebtn").on("click", function(){
        closeNav();
    });
    function openNav() {
        document.getElementById("mySidenav").style.width = "250px";
    }
     
    function closeNav() {
        var element = document.getElementById("mySidenav");
        if(typeof(element) != 'undefined' && element != null){
            element.style.width = "0";   
        }
    }
    
   setTimeout(function() {
     $('.alert-success').alert('close');
    }, 4000);
    
    setTimeout(function() {
     $('.alert-warning').alert('close');
    }, 4000);
    
    $('[data-toggle="popover"]').popover({
      html: true
    });
   
   
   $("#to").keyup(function(){
       if (this.value.match(/[a-z]/i)) {
            $("#from-phone").addClass("hidden");
        }else{
            $("#from-phone").removeClass("hidden");
        }
    });
    
    $("#offline").click(function(){
        if(this.checked == true){
            $("#invoice-fields").addClass("hidden");  
            $("#invoice_invoice_date").prop('required',false);
        }else{
            $("#invoice-fields").removeClass("hidden");
            $("#invoice_invoice_date").prop('required',true);
        }
    });
    
     $(document).on('click', '#sameAsBilling', function(){
        console.log('ok');
        if(this.checked == true){
            //$("#Shipping").addClass("Shipping");  
            document.getElementById("Shipping").classList.add("Shipping");
            
        }else{
           // $("#Shipping").removeClass("Shipping");
            document.getElementById("Shipping").classList.remove("Shipping");
        }
    });
    
    //initialize and format currency values
    $(document).on("change", ".currency", function(){
        $(this).val(currency($(this).val()));
    });
    
    
    function saveSearchFilter(link){
        var name = prompt("What would you like to call this search filter?");
        if (name != null && name != "") {
            cookie = getCookie("job_links")
            if(cookie == ""){
                //create cookie
                document.cookie="job_links="+ name + "|" + link + "; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/;";
            }else{
                //update cookie
               document.cookie="job_links=" + cookie + "^" + name + "|" + link + "; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/;";;
               
            }
        }
    }
    
    
    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    
    
    se.utils.formatCurrency();
    se.utils.formatQuantity();
    
});
