$(document).ready(function(){
    
    
     $('#selectall-roles').click(function(){
        if(this.checked == true){
            $('.role-cb').prop('checked', true);
        }else{
            $('.role-cb').prop('checked', false);
        }
     });
});