$(document).ready(function(){
   
   $(".save-td-btn").click(function(){
       var $btn = $(this);
       var $tr = $btn.closest("tr");
       $btn.prop("disabled", true);
       
       var $link = $btn.closest("tbody").data("url").toString();
       var test = "ok"
       var vals;
       if($link == "quick_books_terms"){
            vals = { form_data: { name: $tr.find(".name").val(), due_days: $tr.find(".due_days").val() } } 
       }else if($link == "quick_books_accounts"){
            vals = { form_data: { name: $tr.find(".name").val(), account_type: $tr.find(".account_type").val() } } 
       }else{
            vals = { form_data: { name: $tr.find(".name").val() } } 
       }
       $.ajax("/"+ $link +"/"+ $tr.data("id"), {
            method: "PATCH",
            data: vals
        }).fail(function(jqXHR, textStatus) {
            alert("Failed: " + textStatus);
        }).always(function(){
            $btn.prop("disabled", false);
        });
            
   });
   
   
   
   
    
});